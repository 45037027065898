<template>
  <v-app>
    <div class="container">
      <div class="center" v-if="!loading">
        <span v-if="error">{{ error }}</span>
        <PinEntry v-else-if="!pin" :ticket="ticket" />
        <TicketDisplay v-else :boxOffice="boxOffice" :ticket="ticket" />
      </div>
    </div>
  </v-app>
</template>
  
<script>
import Api from '@/services/Api.js'; 
import Utils from '@/services/Utils.js'
import TicketDisplay from "@/components/door/TicketDisplay.vue";
import PinEntry from "@/components/door/PinEntry.vue";
    
export default {
  components: {
    TicketDisplay, PinEntry
  },  

  metaInfo() {
      return this.meta;
  },

  data () {
    return {
      meta:  {
        title: "Door - Openstage"
      },
      status: null,
      boxOffice: null,
      ticket: null,
      event: null,
      error: '',
      loading: true,
    }
  },
  
  async created() {
    this.$vuetify.theme.dark = true
    this.loading = true
    this.load()
  },

  computed: {
    utils: function () {
      return Utils;
    },
    pin () {
      return this.$store.state.door.pin
    }
  },
  
  methods: {

    async load() {
      this.boxOffice = await Api.post(this, "BoxOffice", "read", {id:this.$route.params.boxOfficeId});
      if (this.boxOffice) {
        this.ticket = await Api.post(this, "Ticket", "publicRead", {id:this.$route.params.ticketId}, true);
        if (!this.ticket?.pin) {
          this.error = 'Door scanning unavailable for this ticket. Please try again.'
        }
      } else {
        this.error = 'Error loading box office. Please try again.'
      }
      this.loading = false;
    },

  }

}
</script>

<style>
.container {
  height: 100vh;
  position: relative;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
