<template>
  <v-app>
    <div class="container">
      <div class="center" v-if="!loading">
        <div class="ticket" :style="ticketStyle()">
          <div class="center2 mt-4">
            <div v-if="!boxOffice" style="text-align:center;">
              <h1>Invalid BoxOffice</h1>
            </div>
            <div v-else-if="ticket" style="text-align:center;">
              <h1 v-if="ticket.used" class="mb-5">Used Ticket</h1>
              <h1 v-else class="mb-5">Valid Ticket</h1>
              <h2>{{ticket.ticketNumber}}</h2>
              <h2>{{ticket.orderName}}</h2>
              <h2>{{ticket.eventName}}</h2>
              <h2>{{utils.dateToShortString(ticket.eventTimeStart)}}</h2>
              <h2>{{ticket.ticketTypeName}}</h2>
            </div>
            <div v-else style="text-align:center;">
              <h1>Invalid Ticket</h1>
            </div>
          </div>
        </div>
        <div align="center" class="mt-5">&copy; {{ new Date().getFullYear() }} Openstage</div>
      </div>
    </div>
  </v-app>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js'
    
  export default {
    props: {
      boxOffice: {
        type: Object,
        default: () => ({}),
      },
      ticket: {
        type: Object,
        default: () => ({}),
      },
    },

    metaInfo() {
        return this.meta;
    },

    data () {
      return {
        meta:  {
          title: "Openstage"
        },
        ticket: null,
        boxOffice: null,
        status: null,
        loading: true,
      }
    },
    
    async created() {
      this.$vuetify.theme.dark = true
      this.loading = true
      this.load()
    },

    computed: {
      utils: function () {
        return Utils;
      },
    },
    
    methods: {

      async load() {
        if (this.boxOffice) {
          if (!this.ticket || this.ticket.boxOfficeId != this.boxOffice.id) {
            this.status = "red"
            this.ticket = null
          } else {
            if (this.ticket.used) {
              this.status = "yellow"
            } else {
              this.status = "green"
              Api.post(this, "Ticket", "used", {id:this.ticket.id});
            }
          }
        } else {
          this.status = "red"
        }
        this.loading = false;
      },

      ticketStyle() {
        if (this.status == "green") return "background-color:green;"
        if (this.status == "yellow") return "background-color:yellow; color:black;"
        return "background-color:red;"
      }

    }

  }
</script>

<style>
.container {
  height: 100vh;
  position: relative;
}

.ticket {
  height: 400px;
  width: 300px;
  position: relative;
  border-radius: 25px;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center2 {
  padding:7px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
