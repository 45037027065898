var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{attrs:{"top":"","disabled":!_vm.tooltipText},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({ref:"button",staticClass:"button",class:[
        `button--${_vm.color}`,
        {
          'button--dense': _vm.dense,
          'button--disabled': _vm.loading || _vm.disabled,
          'button--square': _vm.square,
          'button--no-margin': _vm.noMargin,
        }
      ],on:{"click":function($event){!_vm.loading && !_vm.disabled && _vm.$emit('click')},"mousemove":_vm.onMousemove}},'span',attrs,false),on),[_c('span',{staticClass:"d-flex justify-center align-center"},[(!_vm.loading)?_c('span',{staticClass:"text d-flex align-center"},[_vm._t("default")],2):_vm._e(),(_vm.loading)?_c('Loading',{staticClass:"loading",attrs:{"width":40,"infinite":""}}):_vm._e()],1),(!_vm.loading)?_c('span',{staticClass:"hover",style:(_vm.hoverStyle)}):_vm._e()])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])
}
var staticRenderFns = []

export { render, staticRenderFns }