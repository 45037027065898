<template>
  <div>
    <h2 class="text-center">Enter pin</h2>
    <div class="pin-content-container">
        <div class="text-container">
            <div class="font-text-large-1">Enter your one-time pin code for this event</div>
        </div>

        <div id="otp-input" class="font-h2-strong">
            <input v-model="pin1" class="font-h2-strong" placeholder="_" type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*" />
            <input v-model="pin2" class="font-h2-strong" placeholder="_" type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*" />
            <input v-model="pin3" class="font-h2-strong" placeholder="_" type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*" />
            <input v-model="pin4" class="font-h2-strong" placeholder="_" type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*" />
            <input v-model="pin5" class="font-h2-strong" placeholder="_" type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*" />
            <input v-model="pin6" class="font-h2-strong" placeholder="_" type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*" />
            <input id="otp-value" placeholder="_" type="hidden" name="otp" />
        </div>
            <span v-if="error">{{ error }}</span>

        <Button :loading="loadingValidate" :disabled="!pinEntered" @click="validate">Continue</Button>
    </div>
  </div>
</template>
  
<script>
import Utils from '@/services/Utils.js'
import Button from "@/components/elements/Button.vue";
    
  export default {
    components: {
        Button
    },  

    props: {
      ticket: {
        type: Object,
        default: () => ({}),
      },
    },

    data () {
      return {
        error: '',
        loading: false,
        loadingValidate: false,
        pin1: null,
        pin2: null,
        pin3: null,
        pin4: null,
        pin5: null,
        pin6: null
      }
    },
    
    async mounted() {
      this.$vuetify.theme.dark = true
      this.loadPinEntry()
      this.load()
    },

    computed: {
        utils: function () {
            return Utils;
        },
        pinEntry () {
            return `${this.pin1}${this.pin2}${this.pin3}${this.pin4}${this.pin5}${this.pin6}`
        },
        pinEntered () {
            return this.pin1 && this.pin2 && this.pin3 && this.pin4 && this.pin5 && this.pin6
        },
    },
    
    methods: {

      async validate() {
        this.loadingValidate = true
        if (!this.pinEntered) {
            this.loadingValidate = false
            return
        }
        if (this.pinEntry === this.ticket.pin) {
          localStorage.setItem('pin', this.pinEntry)
          this.$store.dispatch("door/setPin", this.pinEntry);
        }
        else {
            this.pin1 = null
            this.pin2 = null
            this.pin3 = null
            this.pin4 = null
            this.pin5 = null
            this.pin6 = null
            this.error = "Incorrect pin. Please try again."
        }
        this.loadingValidate = false
      },

      async load() {
        const pinFromLS = localStorage.getItem('pin')
        if (pinFromLS && pinFromLS === this.ticket.pin){
            this.$store.dispatch("door/setPin", pinFromLS);
            //check ticket vs pin
        }
      },

      loadPinEntry() {
        // Get otp container
        const OTPContainer = document.querySelector("#otp-input");

        // Focus first input
        const firstInput = OTPContainer.querySelector("input");
        firstInput.focus();

        const isValidInput = (inputValue) => {
            return Number(inputValue) === 0 && inputValue !== "0" ? false : true;
        };

        const setInputValue = (inputElement, inputValue) => {
            inputElement.value = inputValue;
        };

        const resetInput = (inputElement) => {
            setInputValue(inputElement, "");
        };

        const focusNext = (inputs, curIndex) => {
            const nextElement = curIndex < inputs.length - 1 ? inputs[curIndex + 1] : inputs[curIndex];

            nextElement.focus();
            nextElement.select();
        };

        const focusPrev = (inputs, curIndex) => {
            const prevElement = curIndex > 0 ? inputs[curIndex - 1] : inputs[curIndex];

            prevElement.focus();
            prevElement.select();
        };

        const focusIndex = (inputs, index) => {
            const element = index < inputs.length - 1 ? inputs[index] : inputs[inputs.length - 1];

            element.focus();
            element.select();
        };

        const handleValidMultiInput = (inputElement, inputValue, curIndex, inputs) => {
            const inputLength = inputValue.length;
            const numInputs = inputs.length;

            const endIndex = Math.min(curIndex + inputLength - 1, numInputs - 1);
            const inputsToChange = Array.from(inputs).slice(curIndex, endIndex + 1);
            inputsToChange.forEach((input, index) => setInputValue(input, inputValue[index]));
            focusIndex(inputs, endIndex);
        };

        const handleInput = (inputElement, inputValue, curIndex, inputs) => {
            if (!isValidInput(inputValue)) return handleInvalidInput(inputElement);
            if (inputValue.length === 1) handleValidSingleInput(inputElement, inputValue, curIndex, inputs);
            else handleValidMultiInput(inputElement, inputValue, curIndex, inputs);
        };

        const handleValidSingleInput = (inputElement, inputValue, curIndex, inputs) => {
            setInputValue(inputElement, inputValue.slice(-1));
            focusNext(inputs, curIndex);
        };

        const handleInvalidInput = (inputElement) => {
            resetInput(inputElement);
        };

        const handleKeyDown = (event, key, inputElement, curIndex, inputs) => {
            if (key === "Delete") {
                resetInput(inputElement);
                focusPrev(inputs, curIndex);
            }
            if (key === "ArrowLeft") {
                event.preventDefault();
                focusPrev(inputs, curIndex);
            }
            if (key === "ArrowRight") {
                event.preventDefault();
                focusNext(inputs, curIndex);
            }
        };

        const handleKeyUp = (event, key, inputElement, curIndex, inputs) => {
            if (key === "Backspace") focusPrev(inputs, curIndex);
        };

        const inputs = OTPContainer.querySelectorAll("input:not(#otp-value)");
        inputs.forEach((input, index) => {
            input.addEventListener("input", (e) => handleInput(input, e.target.value, index, inputs));

            input.addEventListener("keydown", (e) => handleKeyDown(e, e.key, input, index, inputs));

            input.addEventListener("keyup", (e) => handleKeyUp(e, e.key, input, index, inputs));

            input.addEventListener("focus", (e) => e.target.select());
        }); 

      }

    }

  }
</script>

<style lang="scss" scoped>
.font-h2-strong {
    font-weight: 400;
    font-size: 24px;
    line-height: 31.68px;
    font-family: "Lexend", sans-serif;
}

.font-text-large-1 {
    opacity: 0.6;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-family: "Inter", sans-serif;
}

.pin-content-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 32px;
}


.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
}

#otp-input {
  display: flex;
  column-gap: 8px;

  input {
      text-align: center;
      padding: 10px 8px 10px 8px;
      border: 1px solid #adadad;
      border-radius: 4px;
      outline: none;
      height: 64px;
      width: 50px;
      color: white;
  }

  input:focus {
      border: 1px solid rgb(var(--color-secondary));    
  }

  input:focus::placeholder {
      color: transparent;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
  }
}

</style>
